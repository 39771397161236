.modal-content {
    .title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #002f52;
    }

    .under-title {
        font-weight: 500;
        font-size: 14px;
    }

    .send-mail {
        padding: 0 40px;
    }

    .btn-register {
        background: #002f52;
        color: white;
        width: 50%;
        padding: 10px 20px;

        &:hover {
            background: #002f52;
            color: white;
        }
    }
}