.confirm-screen {
  .icon {
    height: 30px;
    width: 32px;
    border: 1px solid;
    // margin-right: 5px;
    background-color: white;
    text-align: center;
    cursor: pointer;
    color: #000;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .btn-confirm {
    width: 250px;
    background-color: yellow;
  }
}

// .confirm-screen .icon {
//   height: 25px;
//   width: 25px;
//   border: 1px solid;
//   margin-right: 5px;
//   background-color: white;
//   text-align: center;
//   cursor: pointer;
//   color: #000;
//   display: inline-flex;
//   justify-content: center;
// }

.icon.active {
  color: #2196f3;
}

.note {
  vertical-align: bottom;
  margin-inline: 4px;
}

.confirm-footer {
  height: 100%;
  bottom: 0;
}

// .confirm-screen .btn-confirm {
//   width: 250px;
//   background-color: yellow;
// }

.no-pointer {
  cursor: unset !important;
}
.confirm-screen-mobile {
  height: 100vh;
}


.cf-navbar{
  position: absolute;
  left: 10px;
  top: 160px;
  max-width: 110px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid rgb(99, 95, 95);
  background-color: rgba(146, 151, 156, 0.723);
}

.cf-list{
  list-style: none;
  padding: 8px 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.cf-item{
  border: 1px solid rgb(14, 10, 248);
  cursor: pointer;
}
.cf-active{
  border: 1px solid red;
}

.cf-item img{
  max-width: 90px;
}


.cf-navbar::-webkit-scrollbar {
  width: 0px;
  /* width of the entire scrollbar */
}

.cf-navbar::-webkit-scrollbar-track {
  background: #878282;
  border-radius: 10px;
  /* color of the tracking area */
}

.cf-navbar::-webkit-scrollbar-thumb {
  background-color: #0883e7;
  /* color of the scroll thumb */
  border-radius: 10px;
  /* roundness of the scroll thumb */
  /* border: 2px solid #da00db; */
  /* creates padding around scroll thumb */
}
.cf-navbar::-webkit-scrollbar {
  width: 6px;
  display: block;
  /* width of the entire scrollbar */
}



@media (max-width: 500px) {
  #m-confirm-btn{
    padding-block: 2px !important;
  }
  .m-resize-wp{
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
  }
  .confirm-screen {
    .icon {
      height: 45px;
      width: 45px;
      svg, span{
        font-size: 30px;
      }
      input{
        font-size: 24px;
      }
    }
  }
  .cf-navbar{
    transform: scale(0.7);
    left: 0px;
    top: 40px;
    // max-width: 110px;
    // max-height: 400px;
  }
  .wp-btn-confirmed-y{
    position: absolute;
    right: -5px;
    padding:2px;
    bottom: 0px;
    background-color: #f8a44c;
    button{
      margin-right: 6px !important;
      padding-inline: 2px !important;
    }
    p{
      font-size: 24px;
    }
  }
  .wp-confirm-tool-y{
    svg{
      font-size: 30px;
    }
    p{
      margin-left: 30px !important;
      font-size: 9px;
    }
  }
}

.cancel-confirm-btn {
  cursor: pointer;
  border-radius: 6px;
  padding: 2px 12px;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap:5px;
  font-size: 14px;
  border: 1px solid #fff;
  background-color: #020cf5;
  background: -webkit-gradient(linear, left top, left bottom, from(#020cf5), to(#03056c));
  background: -webkit-linear-gradient(top, #020cf5 0%, #03056c 100%);
  background: linear-gradient(to bottom, #020cf5 0%, #03056c 100%);
}

.confirmed-gray {
  background-color: gray !important;
  background: gray !important;
  cursor: no-drop !important;
}

@media (max-width: 348px) {
  .wp-btn-confirmed-y{
    bottom: 6px;
  }
}
@media (max-width: 360px) {
  .wp-confirm-tool-y{
    p{
      margin-left: 20px !important;
    }
  }
}




// ================= popup rollback file confirmed
.popup-cancel-title{
  display: flex;
  gap:8px;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  font-weight: bold;
  font-size: 21px;
  padding-top: 10px;
}
.popup-cancel-icon{
  width: 40px;
}

.popup-cancel-btn{
  color: black;
  padding-inline: 10px;
}

.popup-cancel-btn-confirm{
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: bold;
}
.pop-cancel-icon-back{
  width: 22px;
}

.popup-cancel-text{
  padding-bottom: 20px;
}

.popup-cancel-btn-wp{
  margin-right: 28px !important;
  border: 1px solid #a3a4a5 !important;
  background-color: white !important;
  color: black !important;
  padding-block: 6px !important;
}

.popup-cancel-btn-confirm-wp{
  padding-block: 6px !important;
  border: 1px solid #232323 !important;
}

.popup-rule-btn-close{
  color:#000 !important;
  border:1px solid black !important;
}

.popup-rule-text{
  text-align: left !important;
  padding-bottom: 8px !important;
}
.popup-rule-text-first{
  text-align: center !important;
  padding-bottom: 20px !important;
}

// .bg-linear-mb{

// }

.cancel-confirm-btn-mb{
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  margin-left: 30px;
  background-color: #020cf5;
  background: -webkit-gradient(linear, left top, left bottom, from(#020cf5), to(#03056c));
  background: -webkit-linear-gradient(top, #020cf5 0%, #03056c 100%);
  background: linear-gradient(to bottom, #020cf5 0%, #03056c 100%);
  margin-bottom: 5px;
  span{
    color: white;
  }
}

.box-detail-wp > div:first-child{
  max-width: 300px;
}