.dialog_navigator_button {
    width: 50px;
    height: 50px;
    border: 1px solid gray;
    box-shadow: 0 0 3px gray;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    color: black;
}

.zoom_button {
    cursor: pointer;
}

.tab-0 {
    min-width: 1200px;
}

.tab-0 .icon_filter_default {
    fill: black
}

.page_active {
    background-color: #fcff05;
}


.button_dropzone {
    width: 130px;
}

.dropzone-absolute {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #80a0bac1;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  
  .dropzone-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60%;
  }
  
  .dropzone-item {
    color: white;
    font-weight: 600;
  }
  
  .icon-dropzone {
    font-size: 60px !important;
}

.skew-icon {
    transform: rotate(-45deg);
}

@media (max-width: 500px){
    .f-navbar-guest{
        top: 150px !important;
    }
    .f-btn-navbar-guest{
        top: 115px !important;
    }
}

.draw-image-box {
    max-height: calc(100vh - 250px);
}